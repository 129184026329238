// constants
import { Contract, providers, utils } from "ethers";
// log
import { fetchData } from "../data/dataActions";
import ERC20ABI from "../../config/weth-abi.json";
import NFT_ABI from "../../config//abi.json";
import CONFIG from "../../config/config.json";

const mintingToken = "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619";
const contractAddress = CONFIG.CONTRACT_ADDRESS;

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  requestNetworkChange();

  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

export const requestSpendPermissions = async function () {
  try {
    const web3 = new providers.Web3Provider(window.ethereum);

    const erc20Contract = new Contract(
      mintingToken,
      ERC20ABI,
      web3.getSigner()
    );

    let approve_amount =
      "115792089237316195423570985008687907853269984665640564039457584007913129639935"; //(2^256 - 1 )

    const tx = await erc20Contract.approve(
      contractAddress,
      BigInt(approve_amount).toString()
    );
    console.log(tx);
    await tx.wait();
    window.location.reload();
    return tx;
  } catch (err) {
    console.log(err);
    window.location.reload();
  }
};

const requestNetworkChange = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: utils.hexlify(CONFIG.NETWORK.ID) }],
    });
  } catch (switchError) {
    console.error("Network change error:", switchError);
  }
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());

    if (window.ethereum && window.ethereum.isMetaMask) {
      const provider = new providers.Web3Provider(window.ethereum);
      try {
        const accounts = await provider.send("eth_requestAccounts", []);
        const network = await provider.getNetwork();

        if (network.chainId === CONFIG.NETWORK.ID) {
          const signer = provider.getSigner();
          const nftContract = new Contract(contractAddress, NFT_ABI, signer);

          const erc20Contract = new Contract(mintingToken, ERC20ABI, signer);
          const allowance = await erc20Contract.allowance(
            accounts[0],
            contractAddress
          );
          console.log("allowance", allowance.toString());

          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: nftContract,
              erc20Contract: erc20Contract,
              spendableAllowance: allowance.toString(),
              provider: provider,
            })
          );

          // Add listeners
          window.ethereum.on("accountsChanged", (accounts) => {
            window.location.reload();
            dispatch(updateAccount(accounts[0]));
          });
          window.ethereum.on("chainChanged", () => {
            window.location.reload();
          });
        } else {
          dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        console.log(err);
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Install Metamask."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
