import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  connect,
  requestSpendPermissions,
} from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import CONFIG from "./config/config.json";

import BG_IMG from "./config/images/bg.jpg";
import EXAMPLE_IMG from "./config/images/example.gif";
import EXAMPLE_IMG_2 from "./config/images/example2.gif";
import { ethers } from "ethers";
import { fetchTokenData } from "./services";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  font-size: 1.5em;
  font-weight: 700;
  border: 4px solid #0cbc41;
  background-color: transparent;
  transition: 200ms all ease-in-out;
  color: #0cbc41;
  border-radius: 2em;
  padding: 0.5em;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  :hover {
    color: #fff;
    background-color: #0cbc41;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: #0cbc41;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgb(47 191 44 / 70%);
  border: 4px dashed #35db3a;
  background-color: #1ddb1a;
  border-radius: 10%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: blue;
  text-decoration: none;
`;
<st></st>
export default function Mint() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [totalSupply, setTotalSupply] = useState("0");
  const [cost, setCost] = useState("0");
  const spendableAllowance = blockchain.spendableAllowance;
  console.log(spendableAllowance);
  const claimNFTs = async () => {
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    try {
      let tx = await blockchain.smartContract.mintWithERC20(mintAmount);

      await tx.wait();
      setFeedback(`Success! Your ${CONFIG.NFT_NAME} is in your wallet.`);
      setClaimingNft(false);
    } catch (e) {
      console.log(e.error);
      // find revert message from error
      const revertData = e.error.data.message;

      console.log(`Transaction failed: ${revertData}`);
      setFeedback(`Error: ${revertData}`);
      setClaimingNft(false);
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 25) {
      newMintAmount = 25;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const fetchTokenInfo = async () => {
    let tokenData = await fetchTokenData();
    setTotalSupply(tokenData.totalSupply);
    setCost(tokenData.cost);
  };

  useEffect(() => {
    getData();
    fetchTokenInfo();
  }, [blockchain.account]);

  return (
    <s.Screen id="mint" className="App wrapper">
      <s.Container flex={1} ai={"center"} image={BG_IMG}>
        <s.SpacerSmall />
        <h1>Join Our BetNFT Community!</h1>
        <h2>
          Limited to 2,000 BetNFT Greyhounds minted randomly across all the
          bloodlines and their rarities.{" "}
        </h2>
        <h2> G1-5% , G2-15%, G3-30%, G4-50% <br/> The rarer the bloodline the better the attributes.Now which one will you be lucky enough to get!</h2>
        <ResponsiveWrapper flex={1} style={{ padding: "24px" }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={EXAMPLE_IMG} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            className="mintingSection"
          >
            <h4>Mint Your Greyhound NFT Collection!</h4>
            <p>You can mint up to 10 Greyhounds at once.</p>
            <p>
              The price per Greyhound is - {ethers.utils.formatUnits(cost)} Weth
            </p>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container
                      hidden={ethers.BigNumber.from(spendableAllowance).gt(
                        ethers.BigNumber.from(0)
                      )}
                      ai={"center"}
                      jc={"center"}
                      fd={"row"}
                    >
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        hidden={ethers.BigNumber.from(spendableAllowance).gt(
                          ethers.BigNumber.from(cost)
                        )}
                        onClick={(e) => {
                          e.preventDefault();
                          requestSpendPermissions();
                          getData();
                        }}
                      >
                        {"Approve"}
                      </StyledButton>
                    </s.Container>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        hidden={
                          ethers.BigNumber.from(spendableAllowance).lt(
                            ethers.BigNumber.from(cost)
                          ) || ethers.BigNumber.from(cost).eq(0)
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                          fetchTokenInfo();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={EXAMPLE_IMG_2}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper>
      </s.Container>

      <s.Container
        jc={"center"}
        ai={"center"}
        style={{ width: "100%", maxWidth: "800px", margin: "auto" }}
      ></s.Container>
      <iframe
        src="https://umbria.network/widgetv2/"
        height="400"
        width="100%"
        scrolling="no"
      ></iframe>
    </s.Screen>
  );
}
