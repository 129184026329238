import { ethers } from "ethers";
import CONFIG from "../config/config.json";
const FREE_RPC_URL = "https://polygon-rpc.com";

// Query current token count from NFT contract

export const fetchTokenData = async () => {
  const provider = new ethers.providers.JsonRpcProvider(FREE_RPC_URL);

  const contractABI = [
    "function totalSupply() view returns (uint256)",
    "function cost() view returns (uint256)",
  ];
  const contract = new ethers.Contract(
    CONFIG.CONTRACT_ADDRESS,
    contractABI,
    provider
  );

  const totalSupply = await contract.totalSupply();
  const cost = await contract.cost();

  return {
    totalSupply: totalSupply.toString(),
    cost: cost.toString(),
  };
};
