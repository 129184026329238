const initialState = {
  loading: false,
  account: null,
  cost: "",
  smartContract: null,
  erc20Contract: null,
  spendableAllowance: "0",
  provider: null,
  errorMsg: "",
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        smartContract: action.payload.smartContract,
        erc20Contract: action.payload.erc20Contract,
        spendableAllowance: action.payload.spendableAllowance,
        provider: action.payload.provider, // Updated to reflect use of ethers
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
